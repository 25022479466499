.about-me-container {
    background-image: url('../images/japan_temple_small.jpg');
    min-height: 100vh;
}

.about-header {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 45px;
    text-align: left;
}

.about-text {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 24px;
    letter-spacing: 2px;
}

.home-photo {
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.resume-button {
    background-color: rgba(58, 58, 58, 0.85) !important;
    box-shadow: 0rem 0rem 0.5rem 0.5rem rgba(93, 228, 252, 0.8);
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 120% !important;
    margin-top: 2vh;
    text-align: center;
    width: 100%;
}

.resume-button:hover {
    background-color: rgba(184, 184, 184, 0.3) !important;
}
