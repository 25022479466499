.blog-nav {
    background-color: dimgray !important;
    border-radius: .25rem .25rem .25rem .25rem !important;
}

.blog-nav:hover {
    background-color: rgb(179, 175, 175) !important;
}

.blog-nav-margin {
    margin-left: 2vh !important;
}
