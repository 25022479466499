.games-button {
    background-color: rgb(58, 58, 58, 0.5) !important;
    box-shadow: 0rem 0rem 0.5rem 0.5rem rgba(93, 228, 252, 0.8);
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 150% !important;
    margin-top: 2vh;
    text-align: center;
    width: 100%;
}

.games-button:hover {
    background-color: rgba(184, 184, 184, 0.3) !important;
}

.games-container {
    background-image: url('../images/portugal_lisbon_times_square_small.jpg');
    min-height: 150vh;
}

.games-header {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 76px;
    text-align: center;
}

.games-subheader {
    color: white;
    font-family: 'Garamond', Times, Verdana, sans-serif;
    font-size: 40px;
    text-align: center;
}

.games-text-row {
    color: white;
    padding-top: 2vh;
}

@media (max-width: 860px) {
    .games-subheader {
        font-size: 32px;
    }
}

.google-play-btn {
    max-width: 200px;
    max-height: 100px;
}
